<template>
  <div class="sales">
    <div class="header">
      <div class="center">
        <input 
        :class="this.hintShow?'input_red':''" 
        v-model="phone.val" type="text" placeholder="请输入您的手机号" maxlength="11">
        <input v-model="nickName" type="text" placeholder="请输入您的昵称">
        <button @click="apply"></button>
          <p v-show="hintShow">{{hint}}</p>
      </div>
    </div>
  </div>
</template>
<script>
  import Api from '@/untils/api.js'

export default {
  data(){
    return{
      tell_value:'',
      nickName:'',
      hintShow: false,  // 提示语显示
      hint: '手机号码错误', // 提示语
      input_red:'',
      phone: {
        val: '',
        err: false,
        pass: false,
      },
    }
  },
  methods:{
    
    // 申请按钮
    apply(){
      let nowUrl = window.location.search
      let pid = nowUrl.substring(5,6)
      let data ={
        mobile:this.phone.val,
        nickname:this.nickName,
        pid:pid
      }
      Api.addUser({data}).then(res =>{

        if(res.data.code === 200){
        this.$toast(res.data.msg);
          this.phone.val = ''
          this.nickName = ''
        }
        else{
          this.$toast(res.data.msg);
        }
      })
    }
  }
}
</script>
<style  lang="less" scoped>

.sales{
  background-color: #f7f7f7;
  .header{
    background-image: url('~@/assets/images/sales/分销加盟.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
      input{
      background-color: transparent;
      }
      input:nth-child(1){
        border: none;
        position: absolute;
        top: 420px;
        left: 120px;
        // margin-top: 5px;
      }
      input:nth-child(2){
          position: absolute;
          top: 473px;
          // margin-top: 10px;
          left: 120px;

        }
      input::-webkit-input-placeholder {
        color: #fba98c;
      }
      button{
      width: 170px;
      height: 50px;
      border: 1px solid blue;
      background-image: url('~@/assets/images/sales/按钮.png');
      background-size: 100% 100%;
      position: absolute;
      top: 530px;
      left: 100px;
      border: none;
      border-radius: 20px;
      }
  }
}

</style>